import React from "react"

import { footnote, footlink } from "../shared/links"

import styles from "../../styles/main.module.css"

export default function DeepWork() {
    // to do mdash to "&mdash;" inside the p element
    // to do italics do <i></i>
    // to do bold do <b></b>
    // otherwise you can do a span
    // that should cover what we need for nice writing

    // TODO make something that can convert word or docs or open docs into this

    const ul = (ls) => (
        <ul>
            {ls.map(obj => (
            <li>
                {obj}
            </li>
            ))}
        </ul>
    )

    const footnotes = [
        "Keep in mind that a ton of his suggests hinge on consistency and habit-formation. We want to get into the habit of focusing for example, but it won’t happen quickly. Employing his methods, I fathom it would take at least two weeks to a month to start to reap the rewards consistently. The same applies for a lot of the digital minimalism plans (in the first one to three weeks I felt like a fish out of water due to my dependence on social media and many other digital tools).",
        "For working deeply and avoiding distraction from the internet.",
        "One could also view them as self help books. Henceforth, I’ll refer to practical self-help-style books related to the workplace and quotidian life as business books, since their presentation reminds me of what I’ve seen in previous (real) business books I’ve read, such as Built To Last.",
        "It appears to be more useful for me at first glance since it’s focused more on habit formation, for focus, which is generally applicable to many aspects of life. Digital minimalism, on the other hand, is focused, obviously on the digital (mostly internet) aspects of life.",
        "For example something to help schedule social events to not be lonely or whatever.",
        "I’ve also played enough in the past that I can do it half asleep, albeit not at a high level, meaning it hits that nice middle ground between something passive, but also active and social enough to be worth doing if I’m not going to go to sleep yet and cannot concentrate on a better task.",
        "For example Lincoln’s Gettysburg Address, Carl Jung’s works, and Thoreau’s writings.",
        "Reaching out to old friends, instantaneous communications, forming groups to stay together, meeting people, etcetera.",
        "This is also why we take a minimalistic approach. If we can’t win a war, try not to fight it. Obviously, we need to use the tools a little, at least, in our modern lives. That’s why we settle for rules of operation.",
        "Also email. Email can be a surprising culprit, even more so that social media, which we all instinctively realize is not that useful that it should warrant as much attention as it does. Email seems to make us productive. It does not. Do not be fooled. Moderate email use!",
        "These are from a framework for business thinking called the 4DX (four disciplines of execution) method discussed by Cal (which he read about and had good results with).",
        "Of course, I need to be mindful that this is not always necessarily the case, but if the quality of my deep work time is solid and I’m focusing on what matters primarily, I am sure I will yield some output.",
        "Recall the “memorize a deck of cards” section.",
        "A CEO often is scattered because they tend to act as the “gut feeling” of the company. They need to quickly make many decisions based on years of experience, rather than continual deep thought. In my meditation discussions I imagine this might be like focusing abstractly on the field of the company. Generally, to make something from nothing, or from very little, requires deep work.",
        "Instead of a one week retreat, a one day or two day retreat on weekends.",
    ]

    const minimalism_list = [
        (
            <p>
                Doing a digital declutter. This is a thirty day/one month break from social media, infotainment, and other unnecessary digital tools. Anyone attempting this should avoid any unnecessary internet usage. This means that if it is not integral to one’s job or a process or goal necessary for happiness {footlink(5)}, it should be dropped. Everything else should be minimized with strict rules. I personally dropped Facebook, Youtube, Messenger, Discord (which I wasn’t using that much anyways), and Reddit, and only checked email once a day during a scheduled thirty minutes. I began scheduling phone calls with friends to keep up with them (this is a practice I still use). A big advantage of this thirty day shock-treatment is that it makes it clear which tools matter and which tools do not. I found that Messenger and Discord were necessary, so I’ve since kept them (albeit I use them less), but I’ve dropped Facebook and Youtube for good. I have them blocked, though I no longer try to go to them on a whim anyways.
            </p>
        ),
        (
            <p>
                Aiming for more quality leisure. This means engaging in mentally or physically stimulating activities rather than vegetating to TV or the facebook feed or some other equally low-value activity. Good leisure can include things such as Piano, reading compelling books, doing Math, playing board games or video games with friends, conversation, thinking deeply about an interesting subject, learning a skill such as a rubiks cube or memorizing a deck of cards, playing a sport, writing in long-form (not Twitter, not Reddit), etc. As general heuristics, active is better than passive, and social is better than non-social. I’ve mainly been in the reading books camp, though I’ve been aiming to play Piano again for a while. I’ve also been writing a little (through this blog and my personal book reviews) as an effort to improve my writing and consolidate my thoughts. When I can’t get “productive” leisure done I’ll try to aim for whatever is most social and at hand, which sometimes means playing Jackbox/board games with friends and sometimes means just playing a game like Counter Strike in which I get to talk to my teammates {footlink(6)}. What to do specifically will depend on context.
            </p>
        ),
        (
            <p>
                Engaging in solitude. This is not physical solitude, but instead mental solitude. Cal encourages us to be alone with our thoughts, and comfortable being alone with our thoughts. Many great ideas come out of solitude {footlink(7)} and it drives us to be more purposeful about our actions and life at a less frenetic pace. Obviously, Cal does not want us to be lonely, and we all understand that rigorous conversation can help ideas come out more clearly. However, enough solitude is necessary to form quality thoughts. How to pursue mental solitude depends on the situation, but Cal provides good anchoring stories in the book. My approach was primarily long solo walks without my phone or any company. Also, sort of, call office hours.
            </p>
        ),
        (
            <p>
                Using social media with a purpose and rules of operation. One should have rules (a system) to know when to use social media (or infotainment) and how much to use it. Social media has great upsides {footlink(8)}. The problem is that tools like Facebook are “meant” to be used “wrong” negating the upsides and forcing us to experience many of the downsides: scattered attention, information overload, loss of nuance, loss of time, etcetera. To use social media “right” it is important to force oneself to consistently not use it wrong (since the designers want people to use it wrong, and they have both millions of dollars and thousands of engineers for this purpose {footlink(9)}). For me this just meant blocking the sites on /etc/hosts (so that I couldn’t go there on a whim) and then scheduling time to use them {footlink(10)} (usually once at the end of the workday).
            </p>
        ),
    ]

    const deep_list = [
        (
            <p>
                Ritualizing my deep work. I’ve always been against ritualization and splurging, but in theory this is a form of psychological engineering. This also means ending my workday with a ritual so my mind will know that it is over (ties in with resting/down-time a little further down).
            </p>
        ),
        (
            <p>
                Keeping track of how much deep work I am performing. This will help me feel excited about deep work and want to increase my quantities. It gamifies the process. (4DX {footlink(11)})
            </p>
        ),
        (
            <p>
                Focusing only on what matters. All else should be ignored if what matters is to shine through. (4DX)
            </p>
        ),
        (
            <p>
                Acting on “lead” measures. This roughly means caring about how much deep work I engage in (basically how much input I’m using) more so than what results I get (output). If I focus on results it’s easy to have a myopic, impatient view that makes me anxious and breaks my ability to actually work deeply. Thus I should focus on inputs since outputs will follow {footlink(12)}. (4DX)
            </p>
        ),
        (
            <p>
                Resting when necessary. Not overworking, not burning out. Ending work at 5:30 sharp and not looking at it again till the next day. This will force me to be smart about my time and focus with more intensity (as sports and games did in high school). Also, note that downtime is a good time for the subconscious and whatever work was going to happen during downtime would likely not be fruitful.
            </p>
        ),
        (
            <p>
                Make sure I’m ok being bored. This was also a part of the other book. I think this is not so much a problem for me, but I’ll try to be conscious of it.
            </p>
        ),
        (
            <p>
                Batch low quality shallow work into scheduled blocks where I focus on only it and then forget about it completely. Time outside these blocks must absolutely <i>not</i> be used for low quality activities (even if I’m stumped, I should stay stumped rather than Google something, for example). This should include both home and work, since it’s easy to fall back into the trap at home and make little progress overall.
            </p>
        ),
        (
            <p>
                Avoid loops (when we repeat facts we already know) when stumped and make sure to spend time meant for thinking about one thing, actually thinking about that one thing. This is primarily for long walks I’ll take to think about problems. Being disciplined is important in this regard to make progress. The “loops” issue is actually particularly insidious for math and algorithms.
            </p>
        ),
        (
            <p>
                Practice some daily deep work {footlink(13)} (hopefully outside of my normal work, since that is not that interesting a lot of the time, and/or does not lend itself, since I do not grasp some of the tools deeply enough to be able to give up Google), but also take a week or so every once in a while to do a “retreat” even if not physically (though that can help).
            </p>
        ),
        (
            <p>
                Schedule every minute of my day (albeit, in chunks, and hopefully big chunks that are pretty flexible) to be mindful and purposeful of what I do. This is not because I want to follow the schedule perfectly, but because I want my schedule to be purposeful. If I need to change it I should change the entire remaining schedule as needed. It may take half a dozen times per day, so I should have an efficient system.
            </p>
        ),
        (
            <p>
                Quantify the types of work that I do and order them by deepness. This way I can know what to focus on within what matters.
            </p>
        ),
        (
            <p>
                Create a shallow work budget. Do not do email, messenger (etc) more than is allowed by the budget (at work this might mean talking to my boss or whatever).
            </p>
        ),
        (
            <p>
                Making people who send me emails do more work to receive better emails, and emailing to “close the loop” as soon as possible so that I don’t dilly dally on email chains. Email chains should be at most as long as the TCP handshake. (Recall the process-centric approach to emailing.)
            </p>
        ),
    ]

    return (
        <React.Fragment>
            <h2>Focus is What Matters</h2>
            <hr className={styles.top_seperator} />
            <p><i>Deep Work</i> is the second of Cal Newport’s books that I’ve read after <i>Digital Minimalism which</i> I went over in August before I decided to test out his digital declutter idea. It’s a good book. Intend to talk about both, actually, here since their ideas are related. By combining the pragmatic strategies and tactics Cal suggests in both books, we can live clearer, more meaningful, and more purposeful lives than we would otherwise. I’ve only begun to practice much of what he suggests {footlink(1)} and I’ve already felt some improvement (though I know I can do better going forward).</p>
            <p>Part of my goal with this post is to give myself a straightforward high-level game plan I can implement, and to share it so that anyone reading can leave with some takeaways as well. My other goal is, of course, to explain the gist of these two books and convince others to read them.</p>
            <h3>Overview</h3>
            <p><i>Deep Work</i> unsurprisingly covers the art of working deeply, being able to focus, and avoiding distraction. Cal gives us loads of solid strategies that we can use to aid us in this end. He begins by arguing why a focused life is better lived than a distracted one and why it is also likely to be highly economically profitable in our modern age (due to scarcity and increased demand). Later, he lays down these strategies I’ve mentioned with loads of tactics that may prove helpful. Roughly speaking, we ought to practice deep work and eschew shallow work (such as emails, logistics, etcetera). To this end we should also avoid social media (plus infotainment) like the plague and be comfortable living a less (psychologically) frenetic life. We should be ok being bored. It is in fact much better not to have stimulus all the time.</p>
            <p>In <i>Digital Minimalism</i> Cal lays down another set of actions we can take to live a purposeful, focused, and meaningful life, all clustered around the notion of minimizing our use of the internet. The motivation is super similar to <i>Deep Work. However</i>, because social media and infotainment tend to weigh us down so much when trying to live a deeper life, they warrant an entire book. Note that <i>Digital Minimalism</i> is not about quitting everything. It is in fact, not about quitting anything. The main takeaway is to only use what we <i>really</i> need to pursue goals that <i>matter</i> to us deeply, and to use it with rules of operation to protect us from behavioral engineering from companies like Facebook and Twitter. We want to be mindful when using social media, not mindless. We ought to use it with a purpose, not just to see what people are up to because we have nothing better to do, but because these industry giants have so much capital at their disposal, it’s very hard as a regular facebook user (for example) to do so. Cal begins his book by basically defending this point: that internet moderation is hard due to this “digital arms race,” but that it’s crucial for a good life. Subsequently, he gives us tons of suggestions to improve our usage of the internet, plus some nice discussions on current movements, groups (etc) that may be relevant to us if we are interested in this general wave of digital minimalism.</p>
            <p></p>
            <h3>Review</h3>
            <p>I highly recommend <i>Deep Work</i> and <i>Digital Minimalism</i> to anyone interested in improving their life. The subjects they cover are relevant to almost all of us and will necessarily provide immense benefits with only a few exceptions. The ability to focus is integral to most work that is valuable, and having it empowers us to progress quickly in what matters. It’s hard to get anything done otherwise. I have primarily experimented with Cal’s suggestions in <i>Digital Minimalism</i>, though I intend to follow through more with his recommendations from <i>Deep Work</i> in the future. My experience with the digital declutter was an overall success. I would say that in the middle of a pandemic, quitting most communication mediums was not an excellent idea. However, it has had longer term positive repercussions for me. Firstly, I no longer feel the need to use social media, almost at all. Now when I use it, it is with a purpose. I have stopped for long enough that I am no longer dependent on it. Secondly, I’ve gotten into the habit of doing voice calls more so than texting. It has worked wonders in a pandemic-ridden world. I go out for walks and do calls and feel more connected with friends that I would if I was just texting or using messenger. I would feel even better gaming, or doing some joint activity, but there are friends I have who do not game, and for these calls get the job done.</p>
            <p>Another reason I encourage anyone to read this book is because it’s entertaining. Cal does an excellent job of weaving in various parables with Abraham Lincoln, software businesses, rock paper scissors tournaments, card-deck memorizers, farmers, blacksmiths and more. These really help keep track of his recommendations and infuse his practices with meaning. They evince the “why” of each practice by example, and drew, at least, me into the book, helping tie together a wider range of practices at a time to understand how a broader system {footlink(2)} might work.</p>
            <p>These books are what I like to view as “business” books {footlink(3)}, mainly because they tend to be extremely pragmatic, offering examples, and because they are super straightforward to read and relate to our day to day lives. As such, I know that some more intellectual readers will scoff and find them to be too simple, or not stimulating enough for the “intellect.” I humbly submit that this view is completely wrong and based on bogus assumptions. The value of a book such as <i>Deep Work</i> depends as much on the reader as on the book. Consider this book a set of instructions with some arguments and stories to back them up. It’s absolutely crucial to follow through with the instructions to reap <i>any</i> rewards whatsoever. Thinking alone, cannot do anything. Therefore, it is of utmost importance, when reading such a book, to both consider how certain practices could fit within our lives and make some tentative plans. Then one must follow through with <i>something</i>. I found that the best way to do this was by annotating. I have annotated most of the books with my thoughts and recaps after every chapter to help me remember the main points so that I could execute them later more easily. I also jotted down (albeit not as much as I should have) where I would use Cal’s suggestions. (I intend to do more of that below in this post.) Anyways, I believe that reading in this manner, the exercise will become both meaningful and interesting enough to tickle the intellect.</p>
            <p>As for order, either order is fine. I began with <i>Digital Minimalism</i> because I felt that the internet was taking a toll on me and I simply did not know about <i>Deep Work</i> (so I guess it couldn’t have gone any other way). I think starting with <i>Deep Work</i> may make more sense for readers who do not feel that they have a problem with social media or email or whatever (for me it was primarily messenger chats and Youtube, plus email). <i>Deep Work</i> seems to be more useful in the long term {footlink(4)} but perhaps the thirty day digital declutter call suggested in <i>Digital Minimalism</i> could prove to be an excellent shock treatment to kickstart a better life for some readers.</p>
            <h3>My Plan and Strategies/Tactics</h3>
            <p>There are various different things that one can do after reading these books. I will simply list them and then talk a bit about what I intend to do going forward later (primarily regarding deep work). Key actionables from <i>Digital Minimalism</i> include:</p>
            {ul(minimalism_list)}
            <p>There are other tips and tricks that Cal discusses in his book regarding digital minimalism, but I will not go into them since I do not remember their details (I read this around three months ago). Instead, I encourage readers to keep these four core strategies in mind when implementing tactics. A lot of this is implementation, and that will depend on the context in which one is in. In one form or another, I tried each of these strategies and they’ve proven helpful, though a successful implementation is not necessarily obvious. Usually, in my experience, the simpler the system is, the better. However,  one needs to change one's preferences first (i.e. understand that tons of social media use is not good, and that it being “enjoyable” is a delusion). Then the system can help keep things in check once one already has the right values at heart. Because of this, once again, at the risk of beating a dead horse, I recommend the digital declutter as shock treatment.</p>
            <p>I just finished reading <i>Deep Work</i> so I have not begun implementing all of Cal’s suggestions. However, here as some key ones that stood out to me:</p>
            {ul(deep_list)}
            <p><i>Deep work</i> can allow for great feats that would be impossible otherwise. Part of Microsoft’s success when it was founded stemmed from Bill Gates’ prodigal ability for concentration. He would fall asleep on his keyboard while coding and wake up only to continue coding more. Currently, he has a practice of “think weeks” where he will retreat to a cabin and spend time only reading, thinking, planning, etc. I fathom that most successful people also have a lot of focus (though maybe not <i>that</i> much). Some may be more scattered (consider Jack Doresey as discussed by Cal), but these tend to be edge cases and have a reason for being so {footlink(14)}.</p>
            <p>I think that regaining the ability to work deeply, which I have been slowly losing, will be crucial for my future success. I particularly want to focus on draining low-value work (email, etcetera) as much as possible through batching and internet sabbaths {footlink(15)}. I also want to focus on practicing deep work daily until I have time to make a more structured plan. I roughly know which of my work is deeper and which is shallower, so I will focus on that later. Cal’s suggestions for email seem extremely useful, and his discussion on looping and distraction in walking meditations has important ideas for my future implementation of these walks. Lastly, I intend to finish my workday at 5:30 or 5:00 sharp every day and no later. This might mean starting a tiny bit earlier. That is ok.</p>
            {footnotes.map((note, index) => footnote(index + 1, note))}
            <div className={styles.phone_home}>
            <a href="/">Click me to return home.</a>
            </div>
        </React.Fragment>
    )
}
